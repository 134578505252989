import * as React from 'react';
import { Helmet } from 'react-helmet-async';

// The doc prop contains some metadata about the page being rendered that you can use.
const Wrapper = ({ children, doc }) => (
  <React.Fragment>
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="icon" type="image/png" href="/public/images/icon.png" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Montserrat:400,500,600&display=swap"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Krub:wght@400;500;600&display=swap"
        rel="stylesheet"
      />
      <meta property="og:title" content="Season" />
      <meta property="og:description" content="Country Life Season" />
    </Helmet>
    {children}
  </React.Fragment>
);
export default Wrapper;
